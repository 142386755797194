import { observable, action } from 'mobx'

export default class dropdownMenuStore {

  @observable dropdownStores

  constructor(dropdownStores) {
    this.dropdownStores = dropdownStores
  }

  @action.bound toggle(dropdownStore) {
    dropdownStore.toggle()
    this.collapseAllBut(dropdownStore)
  }

  @action.bound expand(dropdownStore) {
    dropdownStore.expand()
    this.collapseAllBut(dropdownStore)
  }

  @action.bound collapse() {
    this.collapseAllBut()
  }

  collapseAllBut = (dropdownStore) => {
    this.dropdownStores.filter(store => store !== dropdownStore).forEach(dropdownStore => {
      dropdownStore.collapse()
    });
  }
}


