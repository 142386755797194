import { observable, action, runInAction } from 'mobx'
import api from '../../api'

export default class PayoffStore {
  @observable errors
  @observable inProgress = false
  @observable paymentType 
  @observable minimumAmount = 0
  @observable amountTooSmall = false
  @observable sended = false

  //Bank
  @observable bankOwner = ''
  @observable bankIban = ''
  @observable bankBic = ''
  
  //PayPal
  @observable paypalOwner = ''
  @observable paypalEmail = ''

  @observable payoffValue = ''

  @observable errorStore

  constructor(errorStore) {
    this.errorStore = errorStore
    this.fetchMinimumAmount()
  }

  @action.bound setPaymentType(paymentType) {
    this.paymentType = paymentType
  }

  @action.bound setBankOwner(bankOwner) {
    this.bankOwner = bankOwner
  }

  @action.bound setBankIban(bankIban) {
    this.bankIban = bankIban
  }

  @action.bound setBankBic(bankBic) {
    this.bankBic = bankBic
  }

  @action.bound setPaypalOwner(paypalOwner) {
    this.paypalOwner = paypalOwner
  }

  @action.bound setPaypalEmail(paypalEmail) {
    this.paypalEmail = paypalEmail
  }

  @action.bound setPayoffValue(payoffValue) {
    this.payoffValue = payoffValue
  }

  @action.bound async fetchMinimumAmount() {
    if(this.minimumAmount && this.minimumAmount > 0) {
      return
    }
    
    try {
      const response = await api.Payoff.getMinimumPayoffAmount()
      runInAction('fetching minimum amount', () => this.minimumAmount = response.minimumPayoffAmount) 
    } catch(error) {
      runInAction('could not fetch minimum amount', () => this.minimumAmount = 0)
    }
  }

  @action.bound async handleSubmit(e) {
    e.preventDefault()
    this.amountTooSmall = false
    
    if(this.payoffValue < this.minimumAmount) {
      this.amountTooSmall = true 
      return
    } 

    try {
      await api.Payoff.postCashbackPayoff({
        type: this.paymentType,
        bankOwner: this.bankOwner,
        bankIban: this.bankIban,
        bankBic: this.bankBic,
        paypalOwner: this.paypalOwner,
        paypalEmail: this.paypalEmail,
        amount: this.payoffValue
      })
      runInAction('payoff sended', () => this.sended = true)
    } catch(error) {
      runInAction('payoff submit error', () => {
        this.errors = error
        this.errorStore.tryToDisplayTranslatedError(error)
      })
    } finally {
      runInAction('payoff submit finished', () => this.inProgress = false)
    }
  }
}