import { observable, action, runInAction, computed } from 'mobx'
// import moment from 'moment'
import api from '../../api'

export default class EuromillionsDrawingsStore {
  @observable errors
  @observable inProgress = false
  @observable errorStore
  @observable draws
  @observable currentDrawPosition

  selectedNumbers = ['5 + 2', '5 + 1', '5 + 0', '4 + 2', '4 + 1', '3 + 2', '4 + 0', 
                     '2 + 2', '3 + 1', '3 + 0', `1  + 2`, '2 + 1', '2 + 0']

  constructor(errorStore) {
    this.errorStore = errorStore
    this.fetchDraws()
  }

  @action.bound nextDraw() {
    if(this.currentDrawPosition === this.draws.length -1) {
      return
    }

    this.currentDrawPosition = this.currentDrawPosition + 1
  }

  @action.bound prevDraw() {
    if(this.currentDrawPosition === 0) {
      return
    }

    this.currentDrawPosition = this.currentDrawPosition - 1
  }

  @computed get currentDraw() {
    if(!this.draws) {
      return undefined
    }
    
    return this.draws[this.currentDrawPosition]
  }

  @computed get statistics() {
    return this.currentDraw.statistics.map((stat, idx) => {return {...stat, selectedNumber: this.selectedNumbers[idx]}})
  }

  @action.bound async fetchDraws() {
    try {
      const request = await api.Draws.getAll()
      runInAction('Set draws', async () => {
        this.draws = request.sort((lDraw, rDraw) => new Date(lDraw.date) - new Date(rDraw.date))
        this.currentDrawPosition = this.draws.length - 1
      })
    } catch(error) {
      runInAction('Set error for fetchCategories', () => this.error = error)
      this.errorStore.tryToDisplayTranslatedError(error)
    } finally {
      runInAction('Finished fetch categories', () => this.inProgress = false)
    }
  }
}