import React, { Component } from 'react'
import { hot } from 'react-hot-loader'
import { Switch, withRouter, Redirect } from 'react-router-dom'
import { observer, inject } from 'mobx-react'

import Loadable from './MyLoadable'
import CustomRoute from './CustomRoute'

import Header from './Header/index'
import Footer from './Footer'
//
const Home = Loadable({ loader: () => import('./Home') })
const SharedAccount = Loadable({
	loader: () => import('./SharedAccount')
})
const Modal = Loadable({ loader: () => import('./Modal') })
const Error = Loadable({ loader: () => import('./Error') })
const Page = Loadable({ loader: () => import('./Page') })
const FAQ = Loadable({ loader: () => import('./FAQ') })

const BoutiqueHome = Loadable({
	loader: () => import('./Boutique/Home')
})
const Subcategory = Loadable({
	loader: () => import('./Boutique/Subcategory')
})
const BoutiqueCategory = Loadable({
	loader: () => import('./Boutique/Category')
})
const SearchBrands = Loadable({
	loader: () => import('./Boutique/SearchBrands')
})
const BoutiqueAccount = Loadable({
	loader: () => import('./Boutique/Account')
})
const Payoff = Loadable({
	loader: () => import('./Boutique/Payoff')
})
//easymillions
const EasymillionsHome = Loadable({
	loader: () => import('./Easymillions/Home')
})
const Euromillions = Loadable({
	loader: () => import('./Easymillions/Euromillions')
})
const EuromillionsDrawings = Loadable({
	loader: () => import('./Easymillions/EuromillionsDrawings')
})
const Communities = Loadable({
	loader: () => import('./Easymillions/Communities')
})
const CommunitiesEasymillions = Loadable({
	loader: () => import('./Easymillions/CommunitiesEasymillions')
})
const Blog = Loadable({
	loader: () => import('./Easymillions/Blog')
})
const Winnings = Loadable({
	loader: () => import('./Easymillions/Winnings')
})
const Grids = Loadable({
	loader: () => import('./Easymillions/Grids')
})
const EasymillionsProfile = Loadable({
	loader: () => import('./Easymillions/Profile')
})
const BlogArticle = Loadable({
	loader: () => import('./Easymillions/BlogArticle')
})
const Numbers = Loadable({
	loader: () => import('./Easymillions/Numbers')
})
const Concursos = Loadable({
	loader: () => import('./Concursos')
})
const DataProtection = Loadable({
	loader: () => import('./DataProtection')
})

Home.preload()
EasymillionsHome.preload()
BoutiqueHome.preload()

@withRouter
@inject('commonStore', 'routingStore', 'translationStore')
@observer
class App extends Component {
	render() {
		const { commonStore, routingStore, translationStore } = this.props
		if (translationStore.inProgress) return null

		return (
			<div className='App gotham w-full mx-auto'>
				{/* <DevTools /> */}
				<Header />
				<Modal />
				<Error />
				<Switch>
					<CustomRoute pageTitle='Home' path='/' exact component={Home} />
					<CustomRoute pageTitle='FAQ' path='/faq' exact component={FAQ} />
					<CustomRoute pageTitle='Page' path='/page/:alias' exact component={Page} />
					<CustomRoute pageTitle='Blog' path='/blog' exact component={Blog} />
					<CustomRoute pageTitle='Blog' path='/blog/entry/:id' exact component={BlogArticle} />
					{/* These routes require the user to be logged in */}
					{commonStore.isLoggedIn && (
						<React.Fragment>
							<CustomRoute pageTitle='Account' path='/compte' exact component={SharedAccount} />
							<CustomRoute pageTitle='Boutique' path='/boutique' exact component={BoutiqueHome} />
							<CustomRoute
								pageTitle='Catégorie'
								path='/boutique/categorie/:name'
								exact
								component={BoutiqueCategory}
							/>
							<CustomRoute
								pageTitle='Sous Catégorie'
								path='/boutique/categorie/:catAlias/souscategorie/:subAlias'
								exact
								component={Subcategory}
							/>
							<CustomRoute pageTitle='Recherche' path='/boutique/recherche/' exact component={SearchBrands} />
							<CustomRoute pageTitle='Recherche' path='/boutique/recherche/:alias' exact component={SearchBrands} />
							<CustomRoute pageTitle='Boutique Compte' path='/boutique/compte' exact component={BoutiqueAccount} />
							<CustomRoute
								pageTitle='Demande de Versement'
								path='/boutique/demandedeversement'
								exact
								component={Payoff}
							/>
							<CustomRoute pageTitle='Loterie' path='/loterie' exact component={EasymillionsHome} />
							<CustomRoute pageTitle='Euromillions' path='/loterie/euromillions' exact component={Euromillions} />
							<CustomRoute
								pageTitle='Tirages'
								path='/loterie/euromillions/tirages'
								exact
								component={EuromillionsDrawings}
							/>
							<CustomRoute pageTitle='Communautes' path='/loterie/communautes' exact component={Communities} />
							<CustomRoute
								pageTitle='Easymillions'
								path='/loterie/easymillions'
								exact
								component={CommunitiesEasymillions}
							/>
							<CustomRoute pageTitle='Compte de Gains' path='/loterie/comptedegains' exact component={Winnings} />
							<CustomRoute pageTitle='Vos Numeros' path='/loterie/vosnumeros' exact component={Numbers} />
							<CustomRoute pageTitle='Grilles' path='/loterie/grilles' exact component={Grids} />
							<CustomRoute
								pageTitle='Profil et Services'
								path='/loterie/profiletservices'
								exact
								component={EasymillionsProfile}
							/>
							<CustomRoute pageTitle='Concours' path='/concours' exact component={Concursos} />
							<CustomRoute
								pageTitle='Protection Des Donnees'
								path='/protection-des-donnees'
								exact
								component={DataProtection}
							/>
						</React.Fragment>
					)}
					<Redirect to='/' />
				</Switch>
				<Footer />
			</div>
		)
	}
}

// export default App
export default hot(module)(App)
