import {observable, runInAction, action, computed} from 'mobx';
import api from '../api';

export default class FAQStore {
  @observable faqs = [];
  @observable errors;
  @observable inProgress = true;
  @observable translationStore;

  constructor(translationStore) {
    this.translationStore = translationStore;
    this.fetchFaqs();
  }

  @computed get categories() {
    // console.log(this.translationStore.language)
    // console.log(this.faqs.filter(faq => faq.language === this.translationStore.language).map(faq => faq.category))
    return [
      ...new Set(
        this.faqs.filter(faq => faq.language === this.translationStore.language).map(faq => faq.category),
      ),
    ];
  }

  @action.bound async fetchFaqs() {
    try {
      const response = await api.FAQ.getAll();
      runInAction('set faqs', () => (this.faqs = response));
    } catch (error) {
      runInAction('set faq error', () => (this.errors = error));
    } finally {
      runInAction('fetch faq finished', () => (this.inProgress = false));
    }
  }
}
