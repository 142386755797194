import { observable, action, runInAction, when, reaction, computed } from 'mobx'
import api from '../../api'

class CategoryStore {
  @observable errors
  @observable inProgress = false

  @observable loaded = false

  @observable categoryAlias
  @observable category
  @observable categories

  @observable subcategory

  @observable errorStore
  @observable routingStore
  @observable customerStore

  @computed get parentCategories() {
    return this.categories ? this.categories.filter(category => category.parent === undefined) : []
  }

  @computed get subCategories() {
    return this.categories
      ? this.categories.filter(category => {
          if (!category.parent) {
            return false
          }

          return this.category && category.parent === this.category._id
        })
      : []
  }

  @computed get favCategories() {
    if (this.customerStore && this.customerStore.favoriteCategoriesIDs && this.categories) {
      return this.categories.filter(cat => this.customerStore.favoriteCategoriesIDs.includes(cat._id))
    } else {
      return []
    }
  }

  constructor(errorStore, routingStore, customerStore) {
    this.errorStore = errorStore
    this.routingStore = routingStore
    this.customerStore = customerStore

    this.fetchCategories()

    this.OnSetSubcategoryReaction()
    this.OnSetCategoryReaction()
  }

  @action.bound setCategoryAlias(name) {
    this.categoryAlias = name
  }

  @action.bound setCategory() {
    const found = this.categories.find(category => category.alias === this.categoryAlias)

    // if the found has an parent, it is not a main category
    if (!found || found.parent) {
      this.errors = new Error('category_not_found')
      this.errorStore.tryToDisplayTranslatedErrorMessage('category_not_found')
      return
    }

    this.category = found

    //check if its favorited
    when(
      () => this.customerStore.favoriteCategoriesIDs,
      () => (this.category.isFav = this.customerStore.favoriteCategoriesIDs.includes(this.category._id))
    )
  }

  @action.bound setSubCategory(subcategoryAlias) {
    this.subcategory = this.categories.find(category => category.alias === subcategoryAlias)

    //check if its favorited
    when(
      () => this.customerStore.favoriteCategoriesIDs,
      () => (this.subcategory.isFav = this.customerStore.favoriteCategoriesIDs.includes(this.subcategory._id))
    )
  }

  @action.bound async fetchCategories() {
    try {
      const response = await api.Categories.getAll()
      runInAction('Fetch categories', () => (this.categories = response))
    } catch (error) {
      runInAction('Set error for fetchCategories', () => (this.error = error))
      this.errorStore.tryToDisplayTranslatedError(error)
    } finally {
      runInAction('Finished fetch categories', () => {
        this.loaded = true
        this.inProgress = false
      })
    }
  }

  async OnSetCategoryReaction() {
    await when(() => this.routingStore.location)
    await when(() => this.categories)
    reaction(
      () => this.routingStore.location.pathname,
      () => {
        if (!this.routingStore.location.pathname.includes('/categorie/')) return

        const urlSplit = this.routingStore.location.pathname.split('/')
        const categoryIndex = urlSplit.indexOf('categorie')
        const categoryAlias = urlSplit[categoryIndex + 1]
        this.setCategoryAlias(categoryAlias)
        this.setCategory()
      },
      {
        name: 'set current category',
        fireImmediately: true
      }
    )
  }

  async OnSetSubcategoryReaction() {
    await when(() => this.routingStore.location)
    await when(() => this.categories)
    reaction(
      () => this.routingStore.location.pathname,
      () => {
        if (!this.routingStore.location.pathname.includes('/souscategorie/')) return

        const urlSplit = this.routingStore.location.pathname.split('/')
        const subcategoryAlias = urlSplit[urlSplit.length - 1]
        this.setSubCategory(subcategoryAlias)
      },
      {
        name: 'set current subcategory',
        fireImmediately: true
      }
    )
  }
}

export default CategoryStore
