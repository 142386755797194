import {observable, action, runInAction} from 'mobx';
import api from '../../api';

export default class ConcursosStore {
  @observable error;
  @observable inProgress = false;
  @observable activeConcursoIndex = 0
  @observable allConcursos = [];

  constructor() {
    this.fetchConcursos();
  }

  @action.bound setActiveConcursoIndex(index) {
    this.activeConcursoIndex = index
  }

  @action.bound async fetchConcursos() {
    this.inProgress = true;
    try {
      const response = await api.Concursos.getAll();
      runInAction('Fetch Concursos', () => {
        this.allConcursos = response.sort((a, b) => new Date(a.date) - new Date(b.date))
        this.activeConcursoIndex = this.allConcursos
        .reduce((smallest, current, index) => {
          if(new Date(current.date).getTime() > new Date().getTime() //in the future 
          && new Date(current.date).getTime() < new Date(this.allConcursos[smallest].date).getTime()) {
            smallest = index
          }

          return smallest
        }, this.allConcursos.length - 1)
      });
    } catch (error) {
      runInAction('Set error for fetchConcursos', () => (this.error = error));
    } finally {
      runInAction('Finished fetch Concursos', () => (this.inProgress = false));
    }
  }
}
