import { observable, action, computed } from 'mobx'

export default class CommunitiesEasymillionsStore {
  @observable currentPictureIndex = 0
  @observable pictures = [
    '/img/easymillions/slide/slide1.png',
    '/img/easymillions/slide/slide2.png',
    '/img/easymillions/slide/slide3.png',
    '/img/easymillions/slide/slide4.png',
    '/img/easymillions/slide/slide5.png',
  ] 

  @action.bound prevPicture() {
    if(this.currentPictureIndex === 0) {
      return
    }

    this.currentPictureIndex -= 1
  }

  @action.bound nextPicture() {
    if(this.currentPictureIndex === this.pictures.length - 1) {
      return
    }

    this.currentPictureIndex += 1
  }

  @computed get currentPicture() {
    return this.pictures[this.currentPictureIndex]
  }
}
