import {observable, action, runInAction} from 'mobx';
import api from '../api';

export default class passwordRecoverStore {
  @observable customerID = '';
  @observable success = false;

  @action.bound setCustomerID(customerID) {
    this.customerID = customerID;
  }

  @action.bound async requestNewPassword() {
    if (this.customerID !== '') {
      try {
        const res = await api.Auth.passwordRecover(this.customerID);
        // console.log(res);
      } catch (error) {
        console.log(error);
      }
      // console.log('over');
      runInAction(() => {
        this.success = true;
      });
    }
  }
}
