import {observable, action, runInAction, computed} from 'mobx';
import api from '../../api';
import moment from 'moment';

export default class BlogStore {
  @observable errors;
  @observable inProgress = false;
  @observable errorStore;
  @observable posts;
  @observable categories;
  @observable currentPage = 0;
  @observable chunk = 2; 
  @observable customerStore;

  constructor(errorStore, customerStore) {
    this.errorStore = errorStore;
    this.customerStore = customerStore
    this.fetchPosts();
    this.fetchCategories();
  }

  @action.bound setCurrentChunk(chunk) {
    this.chunk = chunk;
  }

  @action.bound setCurrentPage(number) {
    this.currentPage = number;
  }

  @action.bound getPost(id) {
    if (!this.posts) {
      return;
    }

    return this.posts.find(post => post._id === id);
  }

  @computed get postsChunks() {
    if (!this.posts) {
      return;
    }
    return this.posts.reduce((chunks, value, index, array) => {
      if (index % this.chunk === 0) chunks.push(array.slice(index, index + this.chunk));
      return chunks;
    }, []);
  }

  @computed get postsMoreRecentCount() {
    if (!this.posts || !this.customerStore.postLastVisitMagazinSectionDate) {
      return 0;
    }

    return this.posts.reduce((counter, current) => {
      if((moment(current.date)) > (moment(this.customerStore.postLastVisitMagazinSectionDate))) {
        counter += 1
      }

      return counter
    }, 0)
  }

  @action.bound isLastPost(index) {
    if (!this.postsChunks) {
      return true;
    }
    return index === this.chunk - 1 || !this.postsChunks[this.currentPage][index + 1];
  }

  @action.bound nextPage() {
    if (this.currentPage === this.postsChunks.length - 1) {
      return;
    }

    this.currentPage += 1;
  }

  @action.bound prevPage() {
    if (this.currentPage === 0) {
      return;
    }

    this.currentPage -= 1;
  }

  @action.bound async fetchPosts() {
    try {
      this.inProgress = true
      const request = await api.Blog.getAllPosts();
      runInAction('Set posts', () => {
        this.posts = request.sort((lPost, rPost) => new Date(rPost.date) - new Date(lPost.date))
        this.inProgress = false
      });
    } catch (error) {
      runInAction('Set error for fetchPosts', () => (this.error = error));
      this.errorStore.tryToDisplayTranslatedError(error);
    } finally {
    }
  }

  @action.bound async fetchCategories() {
    try {
      const request = await api.Blog.getAllCategories();
      runInAction('Set categories', () => (this.categories = request));
    } catch (error) {
      runInAction('Set error for fetchCategories', () => (this.error = error));
      this.errorStore.tryToDisplayTranslatedError(error);
    } finally {
      runInAction('Finished fetch categories', () => (this.inProgress = false));
    }
  }
}
