import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'
import axios from 'axios'

import Success from './Success'

const passEventValueTo = func => e => func(e.target.value)

@inject('translationStore', 'passwordRecoverStore')
@observer
class PasswordForget extends Component {
  static propTypes = {
    // prop: PropTypes
  }

  render() {
    const { translate } = this.props.translationStore
    const { passwordRecoverStore } = this.props

    const inputFieldClass =
      'my-1 px-3 py-3 mx-auto md:mx-0 w-full sm:w-4/5 lg:w-3/5 text-blue border border-blue-lighter rounded gotham text-sm'

    const forgetPassword = translate('account_forgotpassword_title')
    const forgetPasswordBody = translate('account_forgotpassword_sentence_1')
    const send = translate('general_button_send')

    return (
      <div className="mt-12 text-pretty-dark-blue min-h-32">
        {passwordRecoverStore.success ? (
          <Success />
        ) : (
          <div className="inline-block w-full float-left text-center md:text-left">
            <p className="mb-2 text-xl font-black">{forgetPassword}</p>
            <p className="pb-4 gotham-book">{forgetPasswordBody}</p>
            <input
              className={inputFieldClass}
              id="login"
              type="text"
              placeholder="Identifiant Easy Millions"
              value={passwordRecoverStore.customerID}
              onChange={passEventValueTo(passwordRecoverStore.setCustomerID)}
            />
            <div
              onClick={passwordRecoverStore.requestNewPassword}
              className="mt-4 cursor-pointer mx-auto md:mx-0 py-2 self-center text-white w-2/5 lg:w-1/5 rounded bg-pretty-dark-blue text-center"
            >
              {send}
            </div>
          </div>
        )}
        <div className="clearfix" />
      </div>
    )
  }
}

export default PasswordForget
