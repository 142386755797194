import { observable, action } from 'mobx'

export default class dropdownStore {

  @observable isExpanded = false

  @action.bound toggle() {
    this.isExpanded = !this.isExpanded
  }

  @action.bound expand() {
    this.isExpanded = true
  }

  @action.bound collapse() {
    // console.log('collpase')
    this.isExpanded = false
  }
}


