import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import Dropdown from './Dropdown'
import BlogStore from '../../../stores/easymillions/blogStore'
import Link from './Dropdown/Content'

@inject(
	'translationStore',
	'euromillionsDropdownStore',
	'communitiesDropdownStore',
	'magazineDropdownStore',
	'accountDropdownStore',
	'easymillionsDropdownMenuStore',
	'blogStore',
	'customerStore'
)
@inject('routingStore')
@observer
class ExpandedMenu extends Component {
	render() {
		const { translate } = this.props.translationStore
		const {
			euromillionsDropdownStore,
			communitiesDropdownStore,
			magazineDropdownStore,
			accountDropdownStore,
			easymillionsDropdownMenuStore,
			blogStore,
			customerStore
		} = this.props
		const { links } = this.props

		const { routingStore, blogFromPublic } = this.props

		const borderClass = false ? '' : 'border-r'

		const activeClass = 'bg-easymillions-menu-active'
		const activeEuromillions = euromillionsDropdownStore.isExpanded ? activeClass : 'py-1'
		const activeCommunities = communitiesDropdownStore.isExpanded ? activeClass : 'py-1'
		const activeMagazine = magazineDropdownStore.isExpanded ? activeClass : 'py-1'
		const activeAccount = accountDropdownStore.isExpanded ? activeClass : 'py-1'

		const communities = translate('header_magazin_communities')
		const magazine = translate('header_magazin')
		const account = translate('easymillions_menu_account')

		return blogFromPublic ? (
			<div
				style={{
					zIndex: 9999999,
					marginLeft: '20px',
					backgroundColor: '#011145',
					borderRadius: 5
				}}
				className={`inline-block relative flex-1 `}
			>
				<div
					className={`absolute rounded text-lg ${
						this.props.showNotification && blogStore.postsMoreRecentCount > 0 ? '' : 'hidden'
					}`}
					style={{
						top: '-50%',
						left: '90%',
						backgroundColor: '#4e81b8',
						padding: '5px 7px 5px 7px'
					}}
				>
					{blogStore.postsMoreRecentCount}
				</div>
				<div>
					<div
						style={{ color: 'white', padding: '7px', width: '68px', textAlign: 'center', fontSize: '100%' }}
						className={`px-6 text-xs text-tinto text-justify cursor-pointer`}
						onClick={() => {
							routingStore.push(`${links.magazineLinks[0].url}`, { blogFromPublic })
						}}
					>
						<p>{links.magazineLinks[0].title}</p>
					</div>
				</div>
			</div>
		) : (
			<React.Fragment>
				<div className={`pl-1 sm:pl-2 rounded-l-full inline-block flex-1 ${borderClass} ${activeEuromillions}`}>
					<Dropdown
						dropdownStore={euromillionsDropdownStore}
						dropdownStoreController={easymillionsDropdownMenuStore}
						title='Euro Millions'
						centered
						left='-50%'
						links={links.euromillionsLinks}
					/>
				</div>
				<div className={`mx-auto inline-block flex-1 ${borderClass} ${activeCommunities}`}>
					<Dropdown
						dropdownStore={communitiesDropdownStore}
						dropdownStoreController={easymillionsDropdownMenuStore}
						title={communities}
						centered
						left='-50%'
						links={links.communitiesLinks}
					/>
				</div>
				<div className={`inline-block relative flex-1 ${borderClass} ${activeMagazine}`}>
					<div
						className={`absolute rounded text-lg ${
							this.props.showNotification && blogStore.postsMoreRecentCount > 0 ? '' : 'hidden'
						}`}
						style={{
							top: '-50%',
							left: '20%',
							backgroundColor: '#4e81b8',
							padding: '5px 7px 5px 7px'
						}}
					>
						{blogStore.postsMoreRecentCount}
					</div>
					<Dropdown
						dropdownStore={magazineDropdownStore}
						dropdownStoreController={easymillionsDropdownMenuStore}
						title={magazine}
						centered
						left='-70%'
						links={links.magazineLinks}
						showNotification
					/>
				</div>
				<div className={`rounded-r-full inline-block flex-1 cursor-pointer ${activeAccount}`}>
					<Dropdown
						dropdownStore={accountDropdownStore}
						dropdownStoreController={easymillionsDropdownMenuStore}
						title={account}
						centered
						left='-90%'
						links={links.accountLinks}
					/>
				</div>
			</React.Fragment>
		)
	}
}

export default ExpandedMenu
