import requests from './requests'
import config from '../utils/config'
import { request } from 'http'

const Auth = {
	login: async (id, password) => {
		if (!id || !password) throw Error('missing_credentials')
		// await requests.post('/easymillions/customer/update', { id, password })
		const res = await requests.post('/easymillions/login', { id, password })
		return res
	},
	passwordRecover: customerId => requests.post('/easymillions/customer/forgotpassword', { customerId })
}

const Blog = {
	getAllCategories: () => requests.get('/frontend_blog/categories'),
	getAllPosts: () => requests.get('/frontend_blog/posts'),
	postLastVisitMagazinSection: id => requests.post('/easymillions/my/customer/lastvisit/magazin', { customerId: id })
}

const Brands = {
	getAll: () =>
		requests.get('/cashback/brands', {
			active: true,
			populate: 'cashback_categories',
			ignore: false
		}),
	search: searchTerm =>
		requests.get('/cashback/brands', {
			search: searchTerm,
			active: true,
			ignore: false
		})
}

const Categories = {
	getAll: () =>
		requests.get('/cashback/categories', {
			active: true,
			sort: 'title'
		})
}

const Concursos = {
	getAll: () =>
		requests.get('/easymillions/concursos', {
			sort: 'date'
		}),
	postLastVisitConcoursSection: (id, lastConcursosDraw) =>
		requests.post('/easymillions/my/customer/lastvisit/concours', { customerId: id, lastConcursosDraw })
}

const Draws = {
	getAll: () => requests.get('/easymillions/euromillionsresults')
}

const FAQ = {
	getAll: () => requests.get('/frontend_faq/faqs')
}

const Jackpot = {
	getCountdown: () => requests.get('/easymillions/euromillionsjackpots')
}

const User = {
	getCustomer: () => requests.get('/crm/my/customer'),
	getEasymillions: () =>
		requests.get('/easymillions/my/customer', {
			populate: 'crm_customer'
		}),
	getEasyboutique: () =>
		requests.get('/cashback/my/customer', {
			populate: 'crm_customer'
		}),
	updateEasyboutiqueNewsletterSubscription: newsletterNews => requests.put('/cashback/my/customer', { newsletterNews }),
	getPayoffs: () =>
		requests.get('/cashback/my/payoffs', {
			populate: 'cashback_customer',
			fields: 'payoffId status amount cashback_customer type bankOwner bankIban bankBic paypalOwner paypalEmail createdAt'
		}),
	postPayoffs: cashbackpayoff => requests.post('/my/cashbackpayoffs', { cashbackpayoff }),
	getTransactions: () =>
		requests.get('/cashback/my/transactions', {
			populate: 'cashback_customer cashback_brand',
			fields: 'transactionId cashback_brand cashback_customer orderId price commission cashback status createdAt'
		}),
	getFavorites: () =>
		requests.get('/cashback/my/customer', {
			populate: 'favoriteCategories'
		}),
	putFavorites: (favoriteCategories, favoriteBrands) =>
		requests.put('/cashback/my/customer', {
			favoriteBrands,
			favoriteCategories
		}),
	putPersonalTips: (id, personalTips) => requests.put(`/easymillions/my/personaltips/${id}`, personalTips),
	customerUpdate: (id, password) => requests.post('/easymillions/customer/update', { id, password })
}

const Options = {
	setHeader: (key, value) => requests.setHeader(key, value)
}

const returnFirstElement = arr => arr[0]
const Pages = {
	getAll: () => requests.get('/frontend_page/pages'),
	getFAQS: () => requests.get('/frontend_faq/faqs'),
	byAlias: alias => requests.get('/frontend_page/pages', { alias }).then(returnFirstElement)
}

const Payoff = {
	getMinimumPayoffAmount: () => requests.get('/cashback/configs/minimumpayoffamount'),
	postCashbackPayoff: body => requests.post('/cashback/my/payoffs', body)
}

const Statistics = {
	getAll: () => requests.get('/easymillions/euromillionsstatistics'),
	boutiqueCount: () => requests.put('/cashback/my/clicks')
}

const Translations = {
	getAll: () => requests.get('/frontend_translation/translations'),
	missingTranslation: key =>
		requests.post('/frontend_translation/missingtranslations', {
			key,
			language: config.locale.slice(0, 2)
		})
}

export default {
	Auth,
	Blog,
	Brands,
	Categories,
	Concursos,
	Draws,
	FAQ,
	Jackpot,
	User,
	Options,
	Pages,
	Payoff,
	Statistics,
	Translations
}
