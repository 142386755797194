import {observable, action, runInAction, computed} from 'mobx';
import moment from 'moment';
import api from '../../api/index';

function Format2Digit(n) {
  return n > 9 ? '' + n : '0' + n;
}

export default class euromillionsStore {
  @observable errors;
  @observable inProgress = false;

  @observable jackpotValue;
  @observable jackpotDueDate;
  @observable refreshCounter = 0;

  @observable errorStore;

  constructor(errorStore) {
    this.errorStore = errorStore;
    if (!this.jackpotValue) {
      this.fetchJackpotData();
    }

    setInterval(() => {
      runInAction('update counter so components can update', () => {
        this.refreshCounter++;
      });
    }, 1000);
  }

  @computed get days() {
    return Format2Digit(moment(this.jackpotDueDate).diff(moment(), 'days'));
  }

  @computed get hours() {
    return Format2Digit(
      moment(this.jackpotDueDate)
        .subtract(this.days, 'days')
        .diff(moment(), 'hours'),
    );
  }

  @computed get minutes() {
    return Format2Digit(
      moment(this.jackpotDueDate)
        .subtract(this.days, 'days')
        .subtract(this.hours, 'hours')
        .diff(moment(), 'minutes'),
    );
  }

  @computed get seconds() {
    return Format2Digit(
      moment(this.jackpotDueDate)
        .subtract(this.days, 'days')
        .subtract(this.hours, 'hours')
        .subtract(this.minutes, 'minutes')
        .diff(moment(), 'seconds'),
    );
  }

  @action.bound async fetchJackpotData() {
    try {
      const response = await api.Jackpot.getCountdown();
      runInAction('Set jackpot value and countdown', () => {
        this.jackpotValue = response[0].value / 1000000;
        this.jackpotDueDate = response[0].time;
      });
    } catch (error) {
      runInAction('Set error for fetchCategories', () => (this.error = error));
      this.errorStore.tryToDisplayTranslatedError(error);
    } finally {
      runInAction('Finished fetch categories', () => (this.inProgress = false));
    }
  }
}
