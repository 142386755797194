import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { observer, inject } from 'mobx-react'

import LoadingSpinner from './LoadingSpinner'

const passEventValueTo = func => e => func(e.target.value)

@inject('translationStore', 'modalStore', 'authStore')
@observer
class Login extends Component {
  constructor() {
    super()
    this.submitOnEnter = this.submitOnEnter.bind(this)
  }

  renderLogin() {
    const { translate } = this.props.translationStore
    const { modalStore } = this.props
    const { authStore } = this.props

    const inputFieldClass = 'my-1 px-3 py-3 w-4/5 text-blue border border-blue-lighter rounded gotham text-sm'

    const logIn = translate('login')
    const username = translate('account_login_username')
    const password = translate('account_login_password')
    const forgetPassword = translate('account_login_forgotpassword')
    const buttonSend = translate('login')

    const becomeAMember = translate('account_register_title')
    const becomeAMemberT1 = translate('account_register_sentence_1')
    const becomeAMemberNumber = translate('account_register_number')
    const becomeAMemberT2 = translate('account_register_sentence_2')
    const error = translate('wrong_username_or_password')

    return (
      <div className="text-pretty-dark-blue">
        <div className="inline-block w-full lg:w-2/5 float-left  ">
          <p className="mb-4 text-xl font-black">{logIn}</p>
          {authStore.errors && <p className="text-sm text-red gotham-book">{error}</p>}
          <input
            id="login"
            className={inputFieldClass}
            type="text"
            placeholder={username}
            value={authStore.id}
            onChange={passEventValueTo(authStore.setId)}
          />
          <input
            id="password"
            className={inputFieldClass}
            type="Password"
            placeholder={password}
            value={authStore.password}
            onChange={passEventValueTo(authStore.setPassword)}
          />
          <p
            onClick={modalStore.showPasswordForget}
            className="mt-4 link text-sm gotham-book hover:text-blue-dark"
          >
            {forgetPassword}
          </p>
          <div
            className="mt-4 cursor-pointer py-2 mb-8 lg:mb-0 text-white w-3/5 rounded bg-pretty-dark-blue text-center"
            onClick={authStore.login}
          >
            {buttonSend}
          </div>
        </div>

        <div className="py-12 px-4 bg-blue-lightest float-left rounded inline-block w-full lg:w-3/5 text-center">
          <p className="pb-4 text-xl font-black">{becomeAMember} </p>
          <p className="pb-2 gotham-book">{becomeAMemberT1}</p>
          <p className="pb-2 gotham-book text-4xl">{becomeAMemberNumber}</p>
          <p className="gotham-book">{becomeAMemberT2}</p>
        </div>
        <div className="clearfix" />
      </div>
    )
  }

  render() {
    const { authStore } = this.props
    return (
      <div className="mt-12 min-h-32">
        {authStore.inProgress ? (
          <div className="h-full my-32">
            <LoadingSpinner />
          </div>
        ) : (
          this.renderLogin()
        )}
      </div>
    )
  }

  submitOnEnter(event) {
    // console.log('called');
    if (event.keyCode === 13) {
      this.props.authStore.login()
    }
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).addEventListener('keydown', this.submitOnEnter)
  }

  componentWillUnmount() {
    ReactDOM.findDOMNode(this).removeEventListener('keydown', this.submitOnEnter)
  }
}

export default Login
