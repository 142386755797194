import {observable, action} from 'mobx';

import Login from '../components/Login';
import PasswordForget from '../components/PasswordForget';

export default class modalStore {
  @observable isVisible = false;
  @observable activeComponent;

  @action.bound showPasswordForget() {
    this.activeComponent = PasswordForget;
    this.isVisible = true;
  }

  @action.bound showLogin() {
    this.activeComponent = Login;
    this.isVisible = true;
  }

  @action.bound setVisible(value = undefined) {
    this.isVisible = value === undefined || typeof value !== 'boolean' ? !this.isVisible : value;
  }
}
