import {observable, action, when, reaction} from 'mobx';
import api from '../api';

export default class pagesStore {
  @observable pages = {};
  @observable loadingPages = true;
  @observable language;

  constructor(routingStore, translationStore) {
    this.routingStore = routingStore;
    this.language = translationStore.language;

    when(
      () =>
        routingStore.location &&
        (routingStore.location.pathname.startsWith('/page') ||
          routingStore.location.pathname.startsWith('/protection-des-donnees')),
      () => this.fetchPages(),
    );
    reaction(
      () => translationStore.language,
      () => {
        this.language = translationStore.language;
        this.fetchPages();
      },
    );
  }

  @action fetchPages() {
    this.loadingPages = true;
    api.Pages.getAll()
      .then(this.transformResponse)
      .then(
        action('Fetch pages', pages => {
          this.pages = pages;
        }),
      )
      .finally(
        action('Fetch pages completed', () => {
          this.loadingPages = false;
        }),
      );
  }

  @action.bound transformResponse(pagesArr) {
    let pagesObj = {};
    pagesArr
      .filter(page => page.language === this.language)
      .forEach(page => {
        pagesObj[page.alias] = Object.assign({}, page)
      })
    return pagesObj
  }
}
