import {observable, action, reaction, computed, when} from 'mobx';
import cookie from 'react-cookies';

// import ReconnectComponent from '../components/Reconnect'

export default class commonStore {
  @observable appName = 'Easymillions';
  @observable token = cookie.load('token');
  @observable appLoaded = false;
  @observable isOnline = true;

  constructor(stores) {
    reaction(
      () => this.token,
      token => {
        if (token) {
          const expires = new Date();
          expires.setDate(expires.getDate() + 90);
          cookie.save('token', token, {path: '/', expires});
        } else {
          cookie.remove('token', {path: '/'});
        }
      },
      {name: 'update token cookie'},
    );

    when(
      () => stores.routingStore.location,
      () =>
        reaction(
          () => stores.routingStore.location,
          () => {
            window.scrollTo(window);
          },
          {name: 'scroll to top on location change'},
        ),
    );

    reaction(() => this.isOnline, () => this.handleConnectionChange(), {
      name: 'Show reconnect error if lost connection',
    });

    this.listenToConnectionChanges();
  }

  @computed get isLoggedIn() {
    return !!this.token;
  }

  @action.bound setToken(token) {
    this.token = token;
  }

  @action.bound setAppLoaded() {
    this.appLoaded = true;
  }

  handleConnectionChange() {
    if (this.isOnline) window.location.reload();
    // else errorStore && errorStore.displayError(<ReconnectComponent/>)
  }

  listenToConnectionChanges() {
    window.addEventListener('offline', this.setIsOnline);
    window.addEventListener('online', this.setIsOnline);
  }

  @action.bound setIsOnline(event) {
    this.isOnline = event.type === 'online';
  }
}
