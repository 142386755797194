import React, {Component} from 'react';
import {Route} from 'react-router-dom';

export default class CustomRoute extends Component {
  changeTitle = () => {
    // console.log('title');
    const {pageTitle} = this.props;
    document.title = pageTitle ? `${pageTitle} | Easymillions.fr` : 'Easymillions.fr';
  };

  render() {
    const {component: RouteComponent} = this.props;
    return (
      <Route
        {...this.props}
        component={undefined}
        render={props => {
          this.changeTitle();
          return <RouteComponent {...props} />;
        }}
      />
    );
  }
}
