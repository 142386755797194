/* eslint-disable no-unused-expressions */
import './styles/bundle.css'
import '@babel/polyfill'

import React from 'react'
import ReactDOM from 'react-dom'
import { Router } from 'react-router'
const createBrowserHistory = require("history").createBrowserHistory
import { Provider, onError } from 'mobx-react'
import { syncHistoryWithStore, RouterStore } from 'mobx-react-router'
import { configure } from 'mobx'

import './utils/mobxFormatter' // allows to print mobx objs in the chrome console

import { Provider as MobxStoresProviderForHooks } from './hooks/storesHook'
import config from './utils/config'
import App from './components/App'

import CustomerStore from './stores/customerStore'
import TranslationStore from './stores/translationStore'
import AuthStore from './stores/authStore'
import PagesStore from './stores/pagesStore'
import CommonStore from './stores/commonStore'
import ModalStore from './stores/modalStore'
import ErrorStore from './stores/errorStore'
import CategoryStore from './stores/boutique/categoryStore'
import BrandStore from './stores/boutique/brandStore'
import DropdownStore from './stores/dropdownStore'
import EasymillionsDropdownMenuStore from './stores/dropdownMenuStore'
import PayoffStore from './stores/boutique/payoffStore'
import FAQStore from './stores/faqStore'
import EuromillionsStore from './stores/easymillions/euromillionsStore'
import EuromillionsDrawingsStore from './stores/easymillions/euromillionsDrawingsStore'
import CommunitiesStore from './stores/easymillions/communitiesStore'
import CommunitiesEasymillionsStore from './stores/easymillions/communitiesEasymillionsStore'
import BlogStore from './stores/easymillions/blogStore'
import GridStore from './stores/easymillions/gridStore'
import ConcursosStore from './stores/concursos/concursosStore'
import PasswordRecoverStore from './stores/passwordRecoverStore'
import statisticStore from './stores/statisticStore'

const stores = {}
stores.routingStore = new RouterStore(stores)
stores.commonStore = new CommonStore(stores)
stores.translationStore = new TranslationStore(stores)
stores.languageDropdownStore = new DropdownStore()
stores.errorStore = new ErrorStore(stores.translationStore)
stores.brandStore = new BrandStore(stores.errorStore, stores.routingStore, stores)
stores.modalStore = new ModalStore(stores)
stores.authStore = new AuthStore(stores.errorStore, stores.commonStore, stores.modalStore)
stores.pagesStore = new PagesStore(stores.routingStore, stores.translationStore)
stores.categoriesDropdownStore = new DropdownStore()
stores.euromillionsDropdownStore = new DropdownStore()
stores.communitiesDropdownStore = new DropdownStore()
stores.magazineDropdownStore = new DropdownStore()
stores.accountDropdownStore = new DropdownStore()
stores.combinedMenuDropdownStore = new DropdownStore()
stores.easymillionsDropdownMenuStore = new EasymillionsDropdownMenuStore([
  stores.euromillionsDropdownStore,
  stores.communitiesDropdownStore,
  stores.magazineDropdownStore,
  stores.accountDropdownStore
])
stores.concursosStore = new ConcursosStore()
stores.customerStore = new CustomerStore(stores.errorStore, stores.commonStore, stores.brandStore, stores.concursosStore, stores)
stores.categoryStore = new CategoryStore(stores.errorStore, stores.routingStore, stores.customerStore)
stores.payoffStore = new PayoffStore(stores.errorStore)
stores.faqStore = new FAQStore(stores.translationStore)
stores.euromillionsStore = new EuromillionsStore(stores.errorStore)
stores.euromillionsDrawingsStore = new EuromillionsDrawingsStore(stores.errorStore)
stores.communitiesStore = new CommunitiesStore(stores.errorStore)
stores.communitiesEasymillionsStore = new CommunitiesEasymillionsStore()
stores.blogStore = new BlogStore(stores.errorStore, stores.customerStore)
stores.gridStore = new GridStore(stores.errorStore, stores.customerStore)
stores.passwordRecoverStore = new PasswordRecoverStore()
stores.statisticStore = new statisticStore(stores.routingStore)

const browserHistory = createBrowserHistory()
const history = syncHistoryWithStore(browserHistory, stores.routingStore)

configure({
  enforceActions: 'observed'
})
// Global error handler
onError(error => {
  console.log(error)
})

// For easier debugging
window._____APP_STATE_____ = stores

document.title = config.title

// if (config.host === 'localhost') registerServiceWorker()

const initGA = history => {
  const script = document.createElement('script')
  const body = document.getElementsByTagName('body')[0]

  script.async = true;
  script.src = 'https://www.googletagmanager.com/gtag/js?id=G-B2KHWZY3NS';

  body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag() { dataLayer.push(arguments); }
  gtag('js', new Date());

  gtag('config', 'G-B2KHWZY3NS', {
    send_page_view: false
  });

  history.listen(location => {
    gtag('event', 'page_view', {
      page_path: location.pathname + location.search + location.hash,
      page_search: location.search,
      page_hash: location.hash,
    });
  });
}

if (window.location.hostname === 'easymillions.fr') {
  initGA(history)
}

const render = Component => {
  return ReactDOM.render(
    <MobxStoresProviderForHooks stores={stores}>
      <Provider {...stores}>
        <Router history={history}>
          <Component />
        </Router>
      </Provider>
    </MobxStoresProviderForHooks>,
    document.getElementById('root')
  )
}

render(App)

// if (module.hot) {
//   module.hot.accept('./components/App', () => {
//     const NextApp = require('./components/App').default;
//     render(NextApp);
//   });
// }
