import axios from 'axios'
import config from '../utils/config'

const instance = axios.create({
  baseURL: config.apiUrl,
  /// timeout: 1000,
  withCredentials: false,
  headers: {
    'access-token': config.apiKey,
    'Access-Control-Allow-Origin': '*',
  },
})

const responseBody = res => res.data.data

const requests = {
  del: async url => responseBody(await instance.delete(url)),
  get: async (url, params = {}) => responseBody(await instance.get(url, { params })),
  put: async (url, body) => responseBody(await instance.put(url, body)),
  post: async (url, body) => responseBody(await instance.post(url, body)),
  setHeader: (key, value) => { instance.defaults.headers.common[key] = value },
}

export default requests
