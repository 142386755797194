import { observable, action, runInAction, reaction } from 'mobx'
import api from '../api'

export default class StatisticStore {
  @observable routingStore

  constructor(routingStore) {
    this.routingStore = routingStore
    this.boutiqueCount = this.boutiqueCount.bind(this)
    // console.log(routingStore.location)
    reaction(() => routingStore.location, () => this.boutiqueCount())
  }

  boutiqueCount() {
    // console.log(window.location.pathname)
    if (window.location.pathname.indexOf('/boutique/') !== -1) {
      api.Statistics.boutiqueCount()
    }
  }
}
