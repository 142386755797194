import React from 'react'
import { observer, inject } from 'mobx-react'

import LoggedOffHeader from './LoggedOffHeader'
import LoggedInHeader from './LoggedInHeader'

@inject('commonStore', 'routingStore')
@observer
class Header extends React.Component {
  render() {
    const { commonStore, routingStore } = this.props
    return (
      <div className="bg-white pt-3 relative">
        <div className="max-w-90p lg:max-w-xl mx-auto">
          <div className="md:float-left flex items-center">
            <img
              onClick={() => {
                routingStore.push('/')
                window.location.reload()
              }}
              className="mx-auto cursor-pointer"
              src="/img/logo.png"
              alt="logo white"
            />
          </div>
          <div className="md:float-right h-full flex items-center">
            {commonStore.isLoggedIn ? <LoggedInHeader /> : <LoggedOffHeader />}
          </div>
        </div>
        <div className="clearfix pb-3" />
      </div>
    )
  }
}

export default Header
