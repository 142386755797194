import React, { Component } from 'react'

import anime from 'animejs'

const rotate = () => {

  anime({ 
    targets: '#Spinner .fa-spinner',
    rotate: '1turn',
    duration: 2000,
    easing: 'easeInOutBack',
    loop: true,
  })
}

export default class LoadingSpinner extends Component {

  componentDidMount() {
    rotate()
  }

  render() {
    const { faSize } = this.props
    return (
      <div id='Spinner' className='w-full h-full text-grey text-center'>
        <i className={`fas fa-spinner fa-${faSize?faSize:'5x'}`} />
      </div>
    )
  }
}
