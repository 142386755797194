import React from 'react'
import PropTypes from 'prop-types'
import { observer, inject } from 'mobx-react'
import TranslationStore from '../stores/translationStore'

const Footer = ({ routingStore, translationStore }) => {
  const { translate } = translationStore
  const responsiveButton =
    'md:ml-6 md:block md:mb-0' + 'lg:ml-6 lg:inline-block lg:mb-0' + 'xl:ml-6 xl:inline-block lg:mb-0'

  return (
    <div className="bg-footer-blue text-center text-white pt-6">
      <div className={`mb-3 ${responsiveButton}`}>
        <button type="button" className="text-white" onClick={() => routingStore.push('/page/service')}>
          {translate('footer_service')}
        </button>
      </div>
      <div className={`mb-3 ${responsiveButton}`}>
        <button type="button" className="text-white" onClick={() => routingStore.push('/page/cg')}>
          {translate('footer_terms')}
        </button>
      </div>
      <div className={`mb-3 ${responsiveButton}`}>
        <button
          type="button"
          className="text-white"
          onClick={() => routingStore.push('/page/easymillions-infos')}
        >
          {translate('footer_infos')}
        </button>
      </div>
      <div className={`mb-3 ${responsiveButton}`}>
        <button
          type="button"
          className="text-white"
          onClick={() => routingStore.push('/page/protectiondesdonnees')}
        >
          {translate('footer_privacy')}
        </button>
      </div>
      <div className={`mb-3 ${responsiveButton}`}>
        <button
          type="button"
          className="text-white"
          onClick={() => routingStore.push('/page/mentionslegales')}
        >
          {translate('footer_legal')}
        </button>
      </div>
      <div className={`mb-3 ${responsiveButton}`}>
        <button type="button" className="text-white" onClick={() => routingStore.push('/faq')}>
          {translate('footer_faq')}
        </button>
      </div>
      <div className="mt-6">
        <img src="/img/logo-inverted.png" alt="logo" />
      </div>
      <div className="pb-6" />
    </div>
  )
}

Footer.propTypes = {
  translationStore: PropTypes.instanceOf(TranslationStore).isRequired
}

export default inject('translationStore', 'routingStore')(observer(Footer))
