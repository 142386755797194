import { observable, action } from 'mobx'

export default class ErrorStore {
    @observable message 
    @observable modalIsOpen = false
    @observable translationStore

    constructor(translationStore) {
      this.translationStore = translationStore
    }

    /** 
     * Display the response message from the error object, if a translation for the error message exists.
     * @param {Error} errorObject - error object that contains the response
     */
    @action.bound tryToDisplayTranslatedError(errorObject) {
      if (!errorObject.response) {
        return
      }
      
      this.tryToDisplayTranslatedErrorMessage(errorObject.response.message)
    }

    /**
     * Display the message from the given string, if a translation for the message exists.
     * @param {string} message - message to translate 
     */
    @action.bound tryToDisplayTranslatedErrorMessage(message) {
      const translatedErrorMessage = this.translationStore.translate(message)

      if (translatedErrorMessage !== message) {
        this.message = translatedErrorMessage
        this.openModal()
      }
    }

    @action.bound openModal() {
      this.modalIsOpen = true
    }

    @action.bound closeModal() {
      this.modalIsOpen = false
    }
}