import React, { useContext } from 'react'

const StoreContext = React.createContext()

const Provider = props => {
  const { stores } = props
  return <StoreContext.Provider value={{ stores }}>{props.children}</StoreContext.Provider>
}

const useStores = () => {
  const { stores } = useContext(StoreContext)
  return stores
}

export { Provider, useStores }
