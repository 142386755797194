import React from 'react';

const Flag = () => {
  return (
    <div className="my-2">
      <img className="align-middle" src={`/img/header/flags/fr.png`} alt="french" />
    </div>
  );
};

export default Flag;
