import { observable, action, runInAction, computed, reaction, when } from 'mobx'
import api from '../../api'

class BrandStore {
  @observable error
  @observable inProgress = false

  @observable allBrands = []
  @observable filteredBrands = [] // by current subcategory
  @observable searchedBrands = []
  
  @observable errorStore
  @observable routingStore
  @observable customerStore

  @computed get favBrands() {
    if(this.customerStore 
      && this.customerStore.favoriteBrandsIDs
      && this.allBrands.length) {
        return this.allBrands.filter(brand => this.customerStore.favoriteBrandsIDs.includes(brand._id))
    } else {
      return []
    }
  }

  // the ones that have better cashback percentage
  @computed get bestBrands() {
    if(this.allBrands) {
      return this.allBrands.slice().sort((a,b) => {
        return b.maximumCommissionPercent - a.maximumCommissionPercent
      }).slice(0, 6)
    } else {
      return []
    }
  }

  constructor(errorStore, routingStore, stores) {
    this.errorStore = errorStore
    this.routingStore = routingStore

    when(
      () => stores.customerStore !== undefined,
      () => this.customerStore = stores.customerStore,
      {
        delay: 10
      }
    )

    this.fetchBrands()

    this.OnSubcategoryReaction()
    this.OnSearchReaction()
  }

  @action.bound async fetchBrands() {
    this.inProgress = true
    try {
      const response = await api.Brands.getAll()
      runInAction('Fetch brands', () => this.allBrands = response)
    } catch (error) {
      runInAction('Set error for fetchBrands', () => this.error = error)
      this.errorStore.tryToDisplayTranslatedError(error)
    } finally {
      runInAction('Finished fetch brands', () => this.inProgress = false)
    }
  }

  @action filterBrandsByAlias(subCategoryAlias) {
    const getAllAliases = categories => categories.map(cat => cat.alias)
    this.filteredBrands = this.allBrands.filter(brand => getAllAliases(brand.cashback_categories).includes(subCategoryAlias))
  }

  @action async search(searchTerm) {
    this.inProgress = true
    try {
      const response = await api.Brands.search(searchTerm)
      runInAction('Fetch searched brands', () => this.searchedBrands = response)
    } catch (error) {
      runInAction('Fetch searched brands errored', () => this.error = error)
      this.errorStore.tryToDisplayTranslatedError(error)
    } finally {
      runInAction('Finished fetch searched brands', () => this.inProgress = false)
    }
  }

  async OnSubcategoryReaction() {
    await when(() => this.routingStore.location)
    await when(() => this.allBrands.length > 0)
    reaction(
      () => this.routingStore.location.pathname,
      () => {
        if(!this.routingStore.location.pathname.includes('/souscategorie/')) return
        
        const urlSplit = this.routingStore.location.pathname.split('/')
        const subCatAlias = urlSplit[urlSplit.length-1]
        this.filterBrandsByAlias(subCatAlias)
      },
      {
        name: 'filter brands by subcategory when location changes and includes /souscategorie/',
        fireImmediately: true
      }
    )
  }

  async OnSearchReaction() {
    await when(() => this.routingStore.location)
    reaction(
      () => this.routingStore.location.pathname,
      () => {
        if(!this.routingStore.location.pathname.includes('/recherche/')) return
        
        // gets the searchTerm alias from the pathname
        const urlSplit = this.routingStore.location.pathname.split('/')
        const index = urlSplit.indexOf('recherche')
        const searchTerm = urlSplit[index+1]

        this.search(searchTerm)
      },
      {
        name: 'filters brands by searchTerm when location changes and includes /recherche/',
        fireImmediately: true
      }
    )
  }
}

export default BrandStore
