import { observable, action, runInAction } from 'mobx'
import api from '../api'

export default class authStore {
  @observable inProgress = false
  @observable errors = undefined

  @observable values = {
    id: '',
    password: '',
  }

  constructor(errorStore, commonStore, modalStore) {
    this.errorStore = errorStore
    this.commonStore = commonStore
    this.modalStore = modalStore
  }

  @action.bound setId(id) {
    this.values.id = id
  }

  @action.bound setPassword(password) {
    this.values.password = password
  }

  @action reset() {
    this.values.id = ''
    this.values.password = ''
  }

  @action.bound async login() {
    this.inProgress = true
    this.errors = undefined
    try {
      const res = await api.Auth.login(this.values.id, this.values.password)
      runInAction('Login customer', () => {
        this.commonStore.setToken(res)
        this.modalStore.setVisible(false)
      })
    } catch(error) {
      runInAction('Login customer error', () => {
        this.errorStore.tryToDisplayTranslatedError(error)
        this.errors = error.message
      })
    } finally {
      runInAction('Login customer completed', () => this.inProgress = false)
    }
  }

  @action.bound logout() {
    this.commonStore.setToken(undefined)
    window.location.reload()
    return Promise.resolve()
  }
}