import React from 'react';

const Success = () => {
  return (
    <div className="my-32">
      <p className="pb-4 text-center gotham-book text-lg">
        Le nouveau mot de passe a été envoyé avec succès.
      </p>
    </div>
  );
};

export default Success;
