import { observable, action, runInAction, when, computed, reaction } from 'mobx'
import api from '../api'

class CustomerStore {
  @observable errors
  @observable inProgress = false
  @observable loaded = false
  @observable realCustomer
  @observable customerUpdated = false

  //Boutique
  @observable customer
  @observable favoriteCategoriesIDs
  @observable favoriteBrandsIDs
  @observable transactions
  @observable payoffs

  //Easymillions
  @observable customerEasymillions
  @observable errorStore
  @observable commonStore
  @observable categoryStore
  @observable brandStore
  @observable authStore

  @observable currentPersonalNumbers = []
  @observable currentPersonalStars = []
  @observable selectedPersonalNumbers = []
  @observable selectedPersonalStars = []
  
  @observable postLastVisitMagazinSectionDate
  @observable postLastVisitConcoursSectionDate
  @observable visitedConcours = false
  @observable newConcoursDraws = false

  constructor(errorStore, commonStore, brandStore, concursosStore, stores) {
    this.errorStore = errorStore
    this.commonStore = commonStore
    this.brandStore = brandStore
    this.authStore = stores.authStore
    this.concursosStore = concursosStore
    this.stores = stores

    when(() => stores.categoryStore !== undefined, () => (this.categoryStore = stores.categoryStore), {
      delay: 10
    })
    
    when(() => this.commonStore.isLoggedIn, () => {this.fetchCustomerData(); this.requestCustomerUpdate()})
    when(() => this.visitedConcours && this.customerEasymillions && this.concursosStore.allConcursos.length > 0, () => this.postLastVisitConcoursSection())
    when(() => this.customerEasymillions && this.concursosStore && this.concursosStore.allConcursos.length > 0, () => this.hasNewConcursosDraws())

    when(
      () => this.customerEasymillions && stores.routingStore,
      () =>
        reaction(
          () => stores.routingStore.location,
          () => {
            if (stores.routingStore.location.pathname.startsWith('/loterie/vosnumeros')) {
              this.selectedPersonalNumbers = this.customerEasymillions.personalTips
                .slice(0, 5)
                // eslint-disable-next-line
                .map(tip => parseInt(tip))
              this.selectedPersonalStars = this.customerEasymillions.personalTips
                .slice(-2)
                // eslint-disable-next-line
                .map(tip => parseInt(tip))
              this.currentPersonalNumbers = [...this.selectedPersonalNumbers]
              this.currentPersonalStars = [...this.selectedPersonalStars]
            }
          },
          {
            name: 'set selected numbers and stars to current tips when visiting the numbers page',
            fireImmediately: true
          }
        )
    )
    // when();
  }

  isMediaType = (mediaTypesToCheck = []) => {
    return mediaTypesToCheck.includes(this.customerEasymillions.contract_mediaCode)
  }

  @action.bound
  setConcoursVisited() {
    this.visitedConcours = true
  }

  //bolean
  @computed get isSubscribedToBoutiqueNewsletter() {
    return this.customer && this.customer.newsletterNews
  }

  @action.bound hasNewConcursosDraws() {
    const lastConcursosDraw = new Date(this.concursosStore.allConcursos[this.concursosStore.allConcursos.length - 1].date).getTime()

    this.newConcoursDraws = new Date(this.customerEasymillions.lastVisitConcoursSection).getTime() < lastConcursosDraw
  }

  @computed get lastVisitMagazinSection() {
    if(!this.customerEasymillions) {
      return new Date()
    }

    return this.lastVisitMagazinSectionDate
  }

  @computed get contract_phone() {
    return (this.customerEasymillions.contract_phone)
      .trim()
      .split(/([0-9]{2})/)
      .join(' ')
      .trim()
  }

  @action.bound async fetchCustomerData() {
    this.inProgress = true
    try {
      api.Options.setHeader('authorization', this.commonStore.token)

      const [boutiqueCustomer, easymillionsCustomer, payoffs, transactions, realCustomer] = await Promise.all(
        [
          api.User.getEasyboutique(),
          api.User.getEasymillions(),
          api.User.getPayoffs(),
          api.User.getTransactions(),
          api.User.getCustomer()
        ] 
      )
      
      runInAction('Set boutique customer data', () => {
        this.realCustomer = realCustomer
        this.customer = boutiqueCustomer
        this.customerEasymillions = easymillionsCustomer
        this.transactions = transactions
        this.payoffs = payoffs
        this.favoriteCategoriesIDs = boutiqueCustomer.favoriteCategories
        this.favoriteBrandsIDs = boutiqueCustomer.favoriteBrands
        this.postLastVisitMagazinSectionDate = new Date(easymillionsCustomer.lastVisitMagazinSection)
        this.postLastVisitConcoursSectionDate = new Date(easymillionsCustomer.lastVisitConcoursSection)
      })
    } catch (error) {
      if (error.response !== undefined && error.response.status === 401) this.authStore.logout()

      this.errorStore.tryToDisplayTranslatedError(error)
      runInAction('Fetch boutique customer data error', () => (this.errors = error))
    } finally {
      runInAction('Fetch boutique customer data finished', () => (this.inProgress = false))
    }
  }

  @action.bound async postLastVisitMagazinSection() {
    try {
      if(!this.customerEasymillions) {
        return
      }

      runInAction('Update customer', () => {this.postLastVisitMagazinSectionDate = new Date()})

      await api.Blog.postLastVisitMagazinSection(this.customerEasymillions._id)
    } catch(error) {
      this.errorStore.tryToDisplayTranslatedError(error)
    }
  }

  @action.bound async postLastVisitConcoursSection() {
    try {
      const lastConcursosDraw = new Date(this.concursosStore.allConcursos[this.concursosStore.allConcursos.length - 1].date)

      runInAction('Update customer', () => {
        this.postLastVisitConcoursSectionDate = lastConcursosDraw
      })

      await api.Concursos.postLastVisitConcoursSection(this.customerEasymillions._id, lastConcursosDraw)
    } catch(error) {
      this.errorStore.tryToDisplayTranslatedError(error)
    }
  }

  @action.bound async requestCustomerUpdate() {
    try {
      if(!this.authStore.values.id || !this.authStore.values.password || this.customerUpdated) {
        return 
      }

      await api.User.customerUpdate(this.authStore.values.id, this.authStore.values.password)
      this.fetchCustomerData()

      
      runInAction('Update customer', () => {this.customerUpdated = true})
    } catch (error) {
      this.errorStore.tryToDisplayTranslatedError(error)
    }
  }

  @action.bound toggleFavoriteCategory(category) {
    if (this.favoriteCategoriesIDs.includes(category._id)) {
      this.favoriteCategoriesIDs = this.favoriteCategoriesIDs.filter(ID => ID !== category._id)
      if (this.categoryStore.category && this.categoryStore.category._id === category._id)
        this.categoryStore.category.isFav = false
      else if (this.categoryStore.subcategory) this.categoryStore.subcategory.isFav = false
    } else {
      this.favoriteCategoriesIDs.push(category._id)
      if (this.categoryStore.category && this.categoryStore.category._id === category._id)
        this.categoryStore.category.isFav = true
      else if (this.categoryStore.subcategory) this.categoryStore.subcategory.isFav = true
    }
    this.updateFavorites()
  }

  @action.bound toggleFavoriteBrand(brand) {
    if (this.favoriteBrandsIDs.includes(brand._id)) {
      this.favoriteBrandsIDs = this.favoriteBrandsIDs.filter(ID => ID !== brand._id)
    } else {
      this.favoriteBrandsIDs.push(brand._id)
    }
    this.updateFavorites()
  }

  @action.bound setPersonalNumber(number) {
    const alreadSelected = this.selectedPersonalNumbers.find(tip => tip === number)

    // If the number is already selected, the number is taken out
    if (alreadSelected) {
      this.selectedPersonalNumbers.replace(this.selectedPersonalNumbers.filter(tip => tip !== number))
      return
    }

    if (this.selectedPersonalNumbers.length === 5) {
      return
    }

    this.selectedPersonalNumbers.push(number)
  }

  @action.bound setPersonalStar(number) {
    const alreadSelected = this.selectedPersonalStars.find(tip => tip === number)

    // If the number is already selected, the number is taken out
    if (alreadSelected) {
      this.selectedPersonalStars.replace(this.selectedPersonalStars.filter(tip => tip !== number))
      return
    }

    if (this.selectedPersonalStars.length === 2) {
      return
    }

    this.selectedPersonalStars.push(number)
  }

  @action.bound changePersonalTips() {
    if (this.selectedPersonalNumbers.length !== 5 || this.selectedPersonalStars.length !== 2) {
      //hmm
      return
    }

    this.sendPersonalTips()
  }

  @action.bound deletePersonalTips() {
    this.selectedPersonalNumbers = []
    this.selectedPersonalStars = []
  }

  @action.bound resetPersonalTips() {
    this.selectedPersonalNumbers = [...this.currentPersonalNumbers]
    this.selectedPersonalStars = [...this.currentPersonalStars]
  }

  @action.bound async updateEasyboutiqueNewsletterSubscription(bool) {
    try {
      // console.log('sending request:', bool)
      api.User.updateEasyboutiqueNewsletterSubscription(bool)
      runInAction('update newsletter news subscription locally', () => {
        this.customer.newsletterNews = bool
      })
    } catch (err) {
      console.log(err)
    }
  }

  @action.bound async sendPersonalTips() {
    this.inProgress = true
    try {
      await api.User.putPersonalTips(this.customerEasymillions._id, {
        personalTips: this.selectedPersonalNumbers.concat(this.selectedPersonalStars)
      })
      this.fetchCustomerData()
    } catch (error) {
      this.errorStore.tryToDisplayTranslatedError(error)
      runInAction('Send personal Tips error', () => (this.errors = error))
    } finally {
      runInAction('Finished sending personal tips', () => (this.inProgress = false))
    }
  }

  isFavoriteBrand(brand) {
    return this.favoriteBrandsIDs && this.favoriteBrandsIDs.includes(brand._id)
  }

  updateFavorites() {
    return api.User.putFavorites(this.favoriteCategoriesIDs, this.favoriteBrandsIDs).then(() =>
      this.fetchCustomerData()
    )
  }
}

export default CustomerStore
