const configs = [
	{
		host: 'easymillions.fr',
		apiUrl: 'https://api.thunderchild.es/',
		apiKey: 'easymillions-fr',
		locale: 'fr-FR',
		currency: 'EUR',
		title: 'Easymillions',
		favicon: 'https://cdn.thunderchild.es/easymillions-fr/favicon.ico',
		unmillionparjourUrl: 'https://unmillionparjour.fr/'
	}
]

const selectedConfig = configs.find(config => config.host === window.location.hostname)

export default selectedConfig || {
	host: 'localhost',
 	//apiUrl: 'http://localhost:3160',
	apiUrl: 'https://api.thunderchild.es/',
	apiKey: 'easymillions-fr',
	locale: 'fr-FR',
	currency: 'EUR',
	title: 'Easymillions',
	favicon: 'https://cdn.thunderchild.es/easymillions-fr/favicon.ico',
	unmillionparjourUrl: 'https://dev.unmillionparjour.fr/'
}
