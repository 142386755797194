import React from 'react'
import { observable, runInAction, action } from 'mobx'
import cookie from 'react-cookies'

import api from '../api'

export default class translationStore {
  @observable translations = []
  @observable language = cookie.load('language')
  @observable availableLanguages = ['fr']
  @observable error
  @observable inProgress = true

  constructor() {
    this.fetchTranslations()

    if (!this.language) {
      cookie.save('language', this.availableLanguages[0])
      this.language = cookie.load('language')
    }

    this.translate = this.translate.bind(this)
  }

  @action.bound async fetchTranslations() {
    this.inProgress = true
    try {
      const response = await api.Translations.getAll()
      if (response) {
        runInAction('Finished loading translations from API', () => {
          this.translations.replace(response)
        })
      }
    } catch (error) {
      console.log(error)
      runInAction('Error occured at fetchTranslations()', () => (this.error = error))
    } finally {
      runInAction('Translation progress finished', () => (this.inProgress = false))
    }
  }

  @action.bound setLanguage(language) {
    console.warn('this function has been deprecated')
  }

  translate(key, replacements) {
    if (!this.translations || !!this.error) {
      return key
    }

    const translation = this.translations.find(
      translation => translation.key === key && translation.language === this.language
    )

    if (!translation) {
      if (key) {
        api.Translations.missingTranslation(key)
      }
      return key
    }

    let value = translation.value

    if (replacements) {
      replacements.forEach(replacement => {
        if (replacement.html) {
          const string = value.replace(`{{${replacement.key}}}`, replacement.value)
          const d = React.createElement('div', {
            dangerouslySetInnerHTML: {
              __html: string
            }
          })
          value = d
        } else {
          value = value.replace(`{{${replacement.key}}}`, replacement.value)
        }
      })
    }

    return value
  }
}
