import React from 'react';
import PropTypes from 'prop-types';
import { observer, inject } from 'mobx-react';

import Flag from '../Flag';

import TranslationStore from '../../../stores/translationStore';

const LoggedInHeader = ({ translationStore, routingStore, authStore, blogStore, customerStore }) => {
  const logout = () => {
    authStore.logout();
    routingStore.push('/');
    window.location.reload();
  };

  const { translate } = translationStore;

  return (
    <div className="h-full mx-auto py-2 sm:py-0">
      <div
        className="ml-5 inline-block align-middle cursor-pointer"
        onClick={() => routingStore.push('/compte')}
      >
        {routingStore.location.pathname === '/compte' ? (
          <div className="relative" style={{ minWidth: '58px' }}>
            <img className="absolute header-account" src="/img/header/profile-clicked.png" alt="profile" />
          </div>
        ) : (
          <img className="inline relative mt-1" src="/img/header/profile.png" alt="profile" />
        )}
      </div>
      <div className="h-6 ml-5 inline-block" style={{ width: '1px' }}>
        <hr className="bg-grey w-full h-full" />
      </div>
      <div className="ml-5 inline-block">
        <Flag />
      </div>
      <div className="h-6 ml-5 inline-block" style={{ width: '1px' }}>
        <hr className="bg-grey w-full h-full" />
      </div>
      <div className="ml-5 inline-block">
        <span className="link" onClick={logout}>
          {translate('header_account_logout')}
        </span>
      </div>
      <div
        style={{
          zIndex: 9999999,
          marginLeft: '20px',
          backgroundColor: '#011145',
          borderRadius: 5
        }}
        className={`inline-block relative flex-1 `}
      >
        <div
          className={`absolute rounded text-lg ${blogStore.postsMoreRecentCount > 0 ? '' : 'hidden'}`}
          style={{
            top: '-50%',
            left: '90%',
            backgroundColor: '#4e81b8',
            padding: '5px 7px 5px 7px'
          }}
        >
          {blogStore.postsMoreRecentCount}
        </div>
        <div>
          <div
            style={{ color: 'white', padding: '7px', width: '68px', textAlign: 'center', fontSize: '100%' }}
            className={`px-6 text-xs text-tinto text-justify cursor-pointer`}
            onClick={async () => {
              await customerStore.postLastVisitMagazinSection()
              routingStore.push('/blog', { blogFromPublic: false })
            }}
          >
            <p>{translate('header_blog')}</p>
          </div>
        </div>
      </div>
      <div className={`absolute desktop-header`}>
        <img src="https://cdn.thunderchild.es/easymillions-fr/images/stamp-smal.png" />
      </div>
    </div>
  );
};

LoggedInHeader.propTypes = {
  translationStore: PropTypes.instanceOf(TranslationStore).isRequired,
};

export default inject('translationStore', 'routingStore', 'authStore', 'blogStore', 'customerStore')(observer(LoggedInHeader));
