import { observable, action, runInAction, computed, when } from 'mobx'
import api from '../../api'

export default class GridStore {
  @observable errors
  @observable inProgress = false
  @observable errorStore
  @observable currentPageEasymillions = 0
  @observable currentPageExtralux = 0
  @observable chunk = 25
  @observable customerStore
  @observable customer
  @observable month
  @observable week = 0

  constructor(errorStore, customerStore) {
    this.errorStore = errorStore
    this.customerStore = customerStore
    when(
      () => this.customerStore.customerEasymillions !== undefined,
      () => {
        this.customer = customerStore.customerEasymillions
        this.month = this.customer.months.length - 1
        // console.log(this.customer)
        // console.log(this.month)
        this.customer.months = this.customer.months.sort(
          (a, b) => new Date(a.startDate).getTime() - new Date(b.startDate).getTime()
        )
        this.setWeek(this.weeks[0]._id)
      }
    )
  }

  @action.bound resetPages() {
    this.currentPageEasymillions = 0
    this.currentPageExtralux = 0
    this.week = 0
  }

  @action.bound nextMonth() {
    if (!this.customer) {
      return
    }

    if (this.month === this.customer.months.length - 1) {
      return
    }

    this.resetPages()
    this.month += 1
  }

  @action.bound prevMonth() {
    if (!this.customer) {
      return
    }

    if (this.month === 0) {
      return
    }

    this.resetPages()
    this.month -= 1
  }

  @action.bound nextEasymillionsPage() {
    if (!this.customer) {
      return
    }

    if (this.currentPageEasymillions === this.easymillionsCommunitiyNumbers.length - 1) {
      return
    }

    this.currentPageEasymillions += 1
  }

  @action.bound prevEasymillionsPage() {
    if (!this.customer) {
      return
    }

    if (this.currentPageEasymillions === 0) {
      return
    }

    this.currentPageEasymillions -= 1
  }

  @action.bound nextExtraluxPage() {
    if (!this.customer) {
      return
    }

    if (this.currentPageExtralux === this.extraluxCommunityNumbers.length - 1) {
      return
    }

    this.currentPageExtralux += 1
  }

  @action.bound prevExtraluxPage() {
    if (!this.customer) {
      return
    }

    if (this.currentPageExtralux === 0) {
      return
    }

    this.currentPageExtralux -= 1
  }

  @computed get lastMonth() {
    return !this.customer || this.month === this.customer.months.length - 1
  }

  @computed get firstMonth() {
    return !this.customer || this.month === 0
  }

  @computed get euromillionsTips() {
    if (
      !this.customer ||
      this.customer.months[this.month].tips.length === 0 ||
      this.customer.months[this.month].tips.personal.euromillions.length == 0
    ) {
      return
    }

    return this.customer.months[this.month].tips.personal.euromillions[0].tipPlayed.split(',')
  }

  @computed get extraluxTips() {
    if (!this.customer || this.customer.months[this.month].tips.personal.extralux.length == 0) {
      return
    }

    return this.customer.months[this.month].tips.personal.extralux[0].tipPlayed.split(',')
  }

  @computed get easymillionsCommunitiyNumbers() {
    if (!this.customer) {
      return
    }
    return this.customer.months[this.month].tips.community.euromillions.reduce(
      (chunks, value, index, array) => {
        if (index % this.chunk === 0) chunks.push(array.slice(index, index + this.chunk))
        return chunks
      },
      []
    )
  }

  @computed get extraluxCommunityNumbers() {
    if (!this.customer) {
      return
    }
    return this.customer.months[this.month].tips.community.extralux.reduce((chunks, value, index, array) => {
      if (index % this.chunk === 0) chunks.push(array.slice(index, index + this.chunk))
      return chunks
    }, [])
  }

  @computed get tippsAreAvailable() {
    return !!this.customer && !!this.customer.months[this.month]
  }

  @action.bound setCurrentPageEasymillions(number) {
    this.currentPageEasymillions = number
  }

  @action.bound setCurrentPageExtralux(number) {
    this.currentPageExtralux = number
  }

  @computed get winningNumbers() {}

  @computed get winningStars() {}

  @action.bound setWeek(weekID) {
    this.week = this.customer.months[this.month].draws.findIndex(week => week._id === weekID)
  }

  @computed get weeks() {
    if (!this.customer) {
      return []
    }
    return this.customer.months[this.month].draws.sort(
      (wA, wB) => new Date(wB.eventDate) - new Date(wA.eventDate)
    )
  }

  @computed get weekMainResult() {
    if (!this.customer || this.customer.months[this.month].draws.length === 0) {
      return
    }

    return this.customer.months[this.month].draws[this.week].gameResult.mainResult.split(',')
  }

  @computed get weekSecondaryResult() {
    if (!this.customer || this.customer.months[this.month].draws.length === 0) {
      return
    }

    return this.customer.months[this.month].draws[this.week].gameResult.secondaryResult.split(',')
  }

  @computed get weekExtraluxResult() {
    if (
      !this.customer ||
      this.customer.months[this.month].draws.length === 0 ||
      !this.customer.months[this.month].draws[this.week].gameResult.extraluxResult
    ) {
      return
    }

    return this.customer.months[this.month].draws[this.week].gameResult.extraluxResult.split(',')
  }
}
