import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

import Content from './Content'

@inject('customerStore')
@observer
class Dropdown extends Component {
	render() {
		const { dropdownStore, centered, toRight, title, links, left, dropdownStoreController } = this.props

		const controller = dropdownStoreController || dropdownStore

		const expanded = dropdownStore.isExpanded
		return (
			<div
				className={`w-full flex gotham cursor-pointer justify-start ${toRight && 'flex-row-reverse'}`}
				onClick={() => {
					if (this.props.showNotification) {
						this.props.customerStore.postLastVisitMagazinSection()
					}
					controller.toggle(dropdownStore)
				}}
				onBlur={() => controller.collapse(dropdownStore)}
			>
				<div className={`${centered && 'mx-auto'} `} name='dropdown'>
					<span className={`inline-block ${expanded ? 'text-tinto' : 'text-white'}`}>{title}</span>
					<span className='fa-stack fa-sm ml-2'>
						<i className={`fas fa-circle fa-stack-2x ${expanded ? 'text-ezred' : 'text-white'}`}></i>
						<i
							className={`fas fa-chevron-down fa-stack-1x fa-inverse ${expanded ? 'text-white ' : 'text-black'}`}
						></i>
					</span>
					{expanded && (
						<div className={`relative`}>
							<Content left={left} links={links} />
						</div>
					)}
				</div>
			</div>
		)
	}
}

export default Dropdown
