import { observable, action, runInAction } from 'mobx'
// import moment from 'moment'
import api from '../../api'

export default class CommunitiesStore {
  @observable errors
  @observable inProgress = false
  @observable errorStore
  @observable statistics

  constructor(errorStore) {
    this.errorStore = errorStore
    this.fetchStatistics()
  }

  @action.bound async fetchStatistics() {
    try {
      const request = await api.Statistics.getAll()
      runInAction('Set draws', () => this.statistics = request[0])
    } catch(error) {
      runInAction('Set error for fetchCategories', () => this.error = error)
      this.errorStore.tryToDisplayTranslatedError(error)
    } finally {
      runInAction('Finished fetch categories', () => this.inProgress = false)
    }
  }
}