import React from 'react';
import Loadable from 'react-loadable';
// https://github.com/jamiebuilds/react-loadable

const Loading = ({error, retry, timedOut, pastDelay}) => {
  if (error) {
    return (
      <div className="max-w-2xl mx-auto px-4">
        <h3 className="text-primary my-2">Loading Error!</h3>
        <button className="btn-primary my-2" onClick={retry}>
          Retry
        </button>
      </div>
    );
  } else if (timedOut) {
    return (
      <div className="max-w-2xl mx-auto px-4 my-2">
        <h3 className="text-primary">Taking a long time... </h3>
        <button className="btn-primary my-2" onClick={retry}>
          Retry
        </button>
      </div>
    );
  } else if (pastDelay) {
    return <div />;
  } else {
    return null;
  }
};

export default function MyLoadable(opts) {
  return Loadable(
    Object.assign(
      {
        // loader: () => import('./components/Bar'),    REQUIRED IN 'opts'
        loading: Loading,
        delay: 200, // 0.2 seconds
        timeout: 10000, // 10 seconds
      },
      opts,
    ),
  );
}
