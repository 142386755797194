import React, { Component } from 'react'
import { observer, inject } from 'mobx-react'

@inject('routingStore')
@observer
class Link extends Component {
	state = {
		isHovered: false
	}

	setHovering = value => () => this.setState({ isHovered: value })

	render() {
		const { linkData, index, length, routingStore, blogFromPublic } = this.props
		const { isHovered } = this.state
		return (
			<div
				className={`px-6 text-xs text-tinto text-justify cursor-pointer ${isHovered && 'font-black'}`}
				onMouseEnter={this.setHovering(true)}
				onMouseOver={this.setHovering(true)}
				onMouseLeave={this.setHovering(false)}
				onClick={() => {
					routingStore.push(`${linkData.url}`, { blogFromPublic })
				}}
			>
				<div className={`border-b py-2 ${index === 0 && 'pt-4'} ${index === length - 1 && 'pb-4'}`}>
					{linkData.title}
					{/* {isHovered && <i className='align-middle fas fa-chevron-right fa-pull-right' />} */}
				</div>
			</div>
		)
	}
}

export default class Content extends Component {
	render() {
		const { links, left } = this.props
		return (
			<div className=' w-64 z-50 absolute shadow-lg bg-white rounded-lg' style={{ left: left, top: '10px' }}>
				{links &&
					links.map((link, index) => <Link length={links.length} index={index} linkData={link} key={link.title} />)}
			</div>
		)
	}
}
